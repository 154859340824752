<template>
	<div>
		<user-maintenance-and-access-switch-settings :switch_item="has_default_timezone_data" />
		<div
			v-if="has_default_timezone"
			class="row q-pa-md"
		>
			<q-select
				v-model="selected_country"
				:options="countries"
				:error="has_country_error"
				:error-message="country_error"
				class="col-md-6 col-12"
				label="Country"
				name="country"
				hint="Pick a country"
				@popup-hide="getCountries"
			/>
			<q-select
				v-model="selected_timezone"
				:options="country_timezones"
				:error=has_timezone_error
				:error-message="timezone_error"
				class="col-md-6 col-12"
				label="Timezone"
				name="timezone"
				hint="Pick a timezone from the selected country"
				@popup-hide="setTimezone"
			/>
		</div>
		<user-maintenance-and-access-switch-settings
			v-if="has_default_timezone"
			:switch_item="apply_preferred_timezone_data"
		/>
	</div>
</template>

<script>
import UserMaintenanceAndAccessSwitchSettings from '@/components/datasets/edit/settings/UserMaintenanceAndAccessSwitchSettings.vue'
import TimeZones from '@/utils/countries_timezones.js'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'DefaultTimeZoneSetting',
	components: {
		UserMaintenanceAndAccessSwitchSettings,
	},
	data() {
		return {
			has_default_timezone_data: {
				label: 'Set default timezone',
				name: 'has-default-timezone',
				value: 'has_default_timezone',
				action: 'updateHasDefaultTimezone',
			},
			apply_preferred_timezone_data: {
				label: 'Apply this preferred timezone to all existing dataset users',
				name: 'apply-preferred-timezone',
				value: 'apply_preferred_timezone',
				action: 'updateApplyPreferredTimezone',
			},
			country_timezones: [],
			countries: [],
			time_zones: [],
			selected_country: null,
			selected_timezone: null,
			has_country_error: false,
			country_error: ''
		}
	},
	computed: {
		...mapGetters(['has_default_timezone', 'timezone_error']),
		has_timezone_error() {
			return this.timezone_error ? true : false
		},
	},
	methods: {
		...mapActions(['updateDefaultTimezone']),
		createCountriesList() {
			this.time_zones.forEach((time_zone) => {
				this.countries.push(time_zone.name)
				if (this.$store.getters.default_timezone) {
					time_zone.timezones.filter((item) => {
						if (this.$store.getters.default_timezone === item) {
							this.selected_timezone = item
							this.selected_country = time_zone.name
							this.country_timezones = time_zone.timezones
						}
					})
				} else {
					if (time_zone.name === 'United Kingdom') {
						this.selected_timezone = time_zone.timezones[0]
						this.selected_country = time_zone.name
						this.country_timezones = time_zone.timezones
					}
				}
			})
		},
		getCountries() {
			this.selected_timezone = ''
			this.time_zones.forEach((time_zone) => {
				if (time_zone.name == this.selected_country) {
					this.country_timezones = time_zone.timezones
				}
				if (this.country_timezones.length == 1) {
					this.selected_timezone = this.country_timezones[0]
				} else {
					this.selected_timezone = ''
				}
			})
			this.setTimezone()
		},
		setTimezone() {
			this.updateDefaultTimezone(this.selected_timezone)
		}
	},
	created() {
		this.time_zones = TimeZones.getTimeZones()
		this.createCountriesList()
	},
	watch:{
		selected_country: function(){
			this.has_country_error = false
			this.country_error = ''

			if (!this.selected_country){
				this.has_country_error = true
				this.country_error = 'You must select a country from the list.'
			}
		}
	}
}
</script>

