<template>
	<div>
		<q-list>
			<q-item tag="label" dense class="q-my-sm">
				<q-item-section>
					<q-item-label>{{ switch_item.label }}</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="value" />
				</q-item-section>
			</q-item>
		</q-list>
		<details-button
			v-if="switch_item.tooltip_content"
			class="q-pl-md"
			:tooltip_content="switch_item.tooltip_content"
			:name="switch_item.name"
		/>
	</div>

</template>

<script>
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'UserMaintenanceAndAccessSwitchSettings',
	components: {
		DetailsButton,
	},
	props: {
		switch_item: Object,
	},
	computed: {
		value: {
			get() {
				return this.$store.getters[this.switch_item.value]
			},
			set(val) {
				this.$store.dispatch(this.switch_item.action, val)
			},
		},
	},
}
</script>


