<template>
	<div>
		<div
			v-for="switch_item in switch_items"
			:key="switch_item.label"
			class="settings-wrapper"
		>
			<user-maintenance-and-access-switch-settings :switch_item='switch_item' />
		</div>

		<div class="settings-wrapper">
			<div class="flex justify-between q-pt-md q-px-md">
				<div>
					Bulk send welcome emails
				</div>
				<q-btn
					round
					color="primary"
					icon="send"
					@click="displayModal"
				/>
			</div>
			<details-button
				:tooltip_content="bulk_send_welcome_emails_tooltip_content"
				name="bulk-send-welcome-emails"
				class="q-pl-md"
			/>

			<q-dialog
				v-model="confirm_dialog"
			>
				<q-card>
					<q-card-section class="row items-center">
						<span class="q-ml-sm">{{ message }}</span>
					</q-card-section>

					<q-card-actions align="right">
						<q-btn
							flat
							label="Cancel"
							color="primary"
							v-close-popup
						/>
						<q-btn
							flat
							label="Confirm"
							color="primary"
							v-close-popup
							@click='reissueWelcomeEmail'
						/>
					</q-card-actions>
				</q-card>
			</q-dialog>
		</div>

		<div class="settings-wrapper">
			<default-time-zone-setting/>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import DatasetsAPI from '@/services/api/Datasets.js'
import UserMaintenanceAndAccessSwitchSettings from '@/components/datasets/edit/settings/UserMaintenanceAndAccessSwitchSettings.vue'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
import DefaultTimeZoneSetting from '@/components/datasets/edit/settings/DefaultTimeZoneSetting.vue'

export default {
	name: 'UserMaintenanceAndAccess',
	components: {
		UserMaintenanceAndAccessSwitchSettings,
		DetailsButton,
		DefaultTimeZoneSetting
	},
	data() {
		return {
			verification_email_tooltip_content: `Enabling this feature will mean that any new CONTACT users created will automatically receive a welcome
			email with log in credentials. Existing CONTACT users will not receive a welcome email automatically; you should use “Bulk send welcome emails”
			to issue credentials to existing CONTACT users.`,
			bulk_send_welcome_emails_tooltip_content: `This feature will send a welcome email containing log in credentials to all users who currently
			have permission to log in, but have never done so. This will include ADMIN, OPERATOR, DATA and SENDER users who have previously received
			a welcome email, and CONTACT users who were created before the “Allow CONTACT users to maintain their own profile`,
			confirm_dialog: false,
			number_of_users: null,
			message: null,
		}
	},
	computed: {
		switch_items() {
			return [
				{
					label: 'Allow CONTACT users to maintain their own profile',
					name: 'send-verification-email',
					value: 'send_verification_email',
					action: 'updateVerificationEmail',
					tooltip_content: this.verification_email_tooltip_content,
				},
				{
					label: 'Prevent ADMIN users changing Datasets via import',
					name: 'locked-users-admin',
					value: 'locked_users_admin',
					action: 'updateLockedUsersAdmin',
					tooltip_content: '',
				},
				{
					label: 'Prevent OPERATOR users changing Datasets via import',
					value: 'locked_users_operator',
					name: 'locked-users-operator',
					action: 'updateLockedUsersOperator',
					tooltip_content: '',
				},
				{
					label: 'Prevent DATA users changing Datasets via import',
					value: 'locked_users_data',
					name: 'locked-users-data',
					action: 'updateLockedUsersData',
					tooltip_content: '',
				},
				{
					label: 'Prevent SENDER users changing Datasets via import',
					value: 'locked_users_sender',
					name: 'locked-users-sender',
					action: 'updateLockedUsersSender',
					tooltip_content: '',
				},
			]
		},
	},
	methods: {
		...mapActions(['updateDialogOptions', 'initAlertDialogOptions']),
		async displayModal() {
			this.number_of_users = await DatasetsAPI.reissueWelcomeEmail(
				this.$route.params.id
			)
			this.confirm_dialog = true
			this.message = 'There are no users who need a new welcome email.'
			if (this.number_of_users) {
				this.message = `You are about to send a welcome email to ${this.number_of_users} users allowing them to log in to Alert Cascade. Are you sure?`
			}
		},
		async reissueWelcomeEmail() {
			await DatasetsAPI.reissueWelcomeEmail(this.$route.params.id, true)
			this.$q.notify('The welcome emails are now being sent.')
		},
	},
}
</script>
